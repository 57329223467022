export const STAGE = process.env.STAGE ?? 'local'
export const IS_PROD = STAGE === 'prod'

const API_BASE_URLS = {
  local: 'http://0.0.0.0:3000',
  // local: 'https://api.radom.network',
  dev: 'https://api.dev.radom.network',
  test: 'https://api.test.radom.network',
  prod: 'https://api.radom.network'
}

export const API_BASE_URL = API_BASE_URLS[STAGE]

export enum RADOM_COLORS {
  BLACK = '#282828',
  BLUE = '#070728',
  BLUE_ALT = '#0E259C',
  BLUE_MED = '#0F1D4B',
  PURPLE = '#9F76EA',
  ORANGE = '#FF6648',
  ORANGE_MED = '#FF8972',
  ORANGE_LIGHT = '#FFDBD4',
  ORANGE_LIGHTEST = '#FFF2EF',
  SUCCESS = '#36AF67',
  SUCCESS_LIGHT = '#d8f3e3',
  WARNING = '#F79009',
  WARNING_LIGHT = '#FEF0C7',
  INFO = '#5490EC',
  INFO_LIGHT = '#d1e1fa',
  ERROR = '#FF5252',
  ERROR_LIGHT = '#ffcccc',
  GRAY_DARKEST = '#636576',
  GRAY_DARKER = '#9B9EB1',
  GRAY_DARK = '#E2E4EF',
  GRAY_MED = '#EFF0F8',
  GRAY_LIGHTEST = '#F8F8FB',
  INPUT_PLACEHOLDER = '#9ba3af',
  NICE_GREEN = '#4bb69c',
  NEW_BLUE = '#3464E6',
  DARK_BLUE = '#07053f'
}

export enum WINDOW_SIZE {
  MOBILE_SMALL = '320px',
  MOBILE_MEDIUM = '375px',
  MOBILE_LARGE = '425px',
  TABLET = '768px',
  LAPTOP = '1024px',
  LAPTOP_LARGE = '1440px',
  DESKTOP = '2560px'
}

export const SecondsToPaymentPeriod = (seconds: number): string => {
  const intervals = {
    1: 'Second',
    60: 'Minute',
    [60 * 60]: 'Hour',
    [60 * 60 * 24]: 'Day',
    [60 * 60 * 24 * 7]: 'Week',
    [60 * 60 * 24 * 30]: 'Month',
    [60 * 60 * 24 * 30 * 3]: 'Quarter',
    [60 * 60 * 24 * 30 * 6]: 'Six Months',
    [60 * 60 * 24 * 365]: 'Year'
  }

  if (intervals[seconds]) {
    return intervals[seconds]
  }

  if (seconds > 60 * 60 * 24 * 365) {
    const years = (seconds / (60 * 60 * 24 * 365)).toFixed(1)
    return `${years} Years`
  }
  if (seconds > 60 * 60 * 24 * 30) {
    const months = (seconds / (60 * 60 * 24 * 30)).toFixed(1)
    return `${months} Months`
  }
  if (seconds > 60 * 60 * 24) {
    const days = (seconds / (60 * 60 * 24)).toFixed(1)
    return `${days} Days`
  }
  if (seconds > 60 * 60) {
    const hours = (seconds / (60 * 60)).toFixed(1)
    return `${hours} Hours`
  }
  if (seconds > 60) {
    const minutes = (seconds / 60).toFixed(1)
    return `${minutes} Minutes`
  }

  return `${seconds} Seconds`
}

export const formatSubscriptionDuration = (numSeconds: number): string => {
  if (SecondsToPaymentPeriod(numSeconds)) {
    return SecondsToPaymentPeriod(numSeconds)
  }

  return `${numSeconds} seconds`
}

const RADOM_CONTRACT_ADDRESSES = {
  31337: '0x749a0F73869eb0b0389F37B2Cee2b8DE5B358baB',
  5: '0x9D4B6432c290D4c393Bbe8fa30b72e240c008F17',
  80001: '0x987296974b23c050d6Eb2BE0CB2A60a1e9CeBa4e',
  4002: '0x819b60B377f99f1c1e8d40B53538b5Dbe1B4a37B',
  11155111: '0x8BBAaaE8225455ce62FCA83FF761fF4895bdD27B',
  1313161555: '0xc17C78B13f9594D974AF55921751B0919E4f377D',
  43113: '0xCc8401Fbca27934D4bFC762FEbb667ACdb976F88',
  534353: '0x3a909d378A6504287C03E0B19FdfB12f84c229e7',
  137: '0x63d76ee7B8b31F8D8CD1D96953C21Adb6fc5E028',
  56: '0x63d76ee7B8b31F8D8CD1D96953C21Adb6fc5E028',
  1: '0x63d76ee7B8b31F8D8CD1D96953C21Adb6fc5E028',
  1313161554: '0x63d76ee7B8b31F8D8CD1D96953C21Adb6fc5E028',
  250: '0x63d76ee7B8b31F8D8CD1D96953C21Adb6fc5E028',
  43114: '0x63d76ee7B8b31F8D8CD1D96953C21Adb6fc5E028',
  66: '0x63d76ee7B8b31F8D8CD1D96953C21Adb6fc5E028'
}

export const getRadomContractAddress = (chainId: number): string => {
  if (RADOM_CONTRACT_ADDRESSES[chainId]) {
    return RADOM_CONTRACT_ADDRESSES[chainId]
  }

  return RADOM_CONTRACT_ADDRESSES[31337]
}

export const EXPLORER_URLS: { [key: string]: string } = {
  RadomDevNet: 'http://localhost:9545',
  GoerliTestnet: 'https://goerli.etherscan.io',
  PolygonTestnet: 'https://mumbai.polygonscan.com',
  FantomTestnet: 'https://testnet.ftmscan.com',
  SepoliaTestnet: 'https://sepolia.etherscan.io',
  BitcoinTestnet: 'https://mempool.space/testnet',
  Bitcoin: 'https://mempool.space',
  AuroraTestnet: 'https://explorer.testnet.aurora.dev',
  Aurora: 'https://explorer.aurora.dev',
  Arbitrum: 'https://arbiscan.io',
  Ethereum: 'https://etherscan.io',
  Fantom: 'https://ftmscan.com',
  AvalancheTestnet: 'https://testnet.snowtrace.io',
  Avalanche: 'https://snowtrace.io',
  ScrollTestnet: 'https://blockscout.scroll.io',
  Polygon: 'https://polygonscan.com',
  BNB: 'https://bscscan.com',
  BNBTestnet: 'https://testnet.bscscan.com',
  PolkadotTestnet: 'https://westend.subscan.io',
  Polkadot: 'https://polkadot.subscan.io',
  TronTestnet: 'https://nile.tronscan.org/#',
  Tron: 'https://tronscan.org/#'
}

export const COUNTRIES = [
  'United Kingdom', 'United States', 'Canada', 'Australia', 'New Zealand', 'South Africa', 'Anguilla',
  'Bermuda', 'British Virgin Islands', 'Cayman Islands', 'Falkland Islands', 'Gibraltar', 'Montserrat',
  'Pitcairn Islands', 'Saint Helena', 'South Georgia and the South Sandwich Islands', 'Turks and Caicos Islands',

  'Albania', 'Andorra', 'Antigua and Barbuda', 'Argentina', 'Armenia',
  'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belgium', 'Belize', 'Bhutan',
  'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi',
  'Cabo Verde', 'Cambodia', 'Cameroon', 'Chile', 'Colombia', 'Comoros',
  'Costa Rica', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic',
  'East Timor (Timor-Leste)', 'Ecuador', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini',
  'Faroe Islands', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany',
  'Ghana', 'Greece', 'Greenland', 'Grenada', 'Guatemala', 'Guernsey', 'Guinea', 'Guinea-Bissau',
  'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Ireland', 'Isle of Man',
  'Israel', 'Italy', 'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kosovo',
  'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein',
  'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Malta', 'Marshall Islands',
  'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro',
  'Morocco', 'Mozambique', 'Namibia', 'Nauru', 'Nepal', 'Netherlands',
  'Nicaragua', 'Niger', 'Nigeria', 'North Macedonia', 'Norway', 'Oman', 'Palau', 'Panama', 'Papua New Guinea',
  'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Rwanda',
  'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa',
  'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone',
  'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Spain', 'Sri Lanka', 'Suriname', 'Sweden', 'Switzerland',
  'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand',
  'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan',
  'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'Uruguay',
  'Uzbekistan', 'Vanuatu', 'Vatican City', 'Vietnam', 'Zambia', 'Zimbabwe'
]
