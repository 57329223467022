import React, { ReactElement, useEffect, useState } from 'react'
import { bonusLevel, CheckoutData } from '../components/Checkout'
import { IManagedPaymentMethodDetails, InputFieldWithValue, IManagedPaymentStatus, IManagedPaymentMethod, IPaymentLinkOrder } from '../api/Radom'
import { NetworkToChainMap, getMethod } from '../util/Managed'
import RadomLogo from '../icons/Logo2'
import { formatNumber, pickTextColorBasedOnBgColor, shortAddress } from '../util/Util'
import { TextInput } from './Input'
import { PrimaryButton, SecondaryButton } from './Button'
import { formatCurrency, formatCurrencyText } from '../util/Currencies'
import { CopyButton, QRCodeWrapper, QRCodeWrapperInner, Wave, WaveContainer, WrapperIn, formatSecondsToMinutesAndSecondsString } from './PayWidget'
import { generateQRCode } from '../util/QRCode'
import styled from 'styled-components'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { createWalletClient, custom, createPublicClient, http, getContract, erc20Abi, parseUnits, Address, parseEther } from 'viem'
import { useDisconnect, useAccount } from 'wagmi'
import { CheckmarkWrapper } from './Receipt'
import Checkmark from '../icons/Checkmark'
import { round, startCase } from 'lodash'
import SolanaWallet from './SolanaWallet'
import { formatDistanceToNow, formatDuration, intervalToDuration } from 'date-fns'
import Close from '../icons/Close'
import { Chevron } from '../icons/Chevron'

const Wrapper = styled.div`
  flex-grow: 1;
  animation: ${WrapperIn} 0.2s ease;
`

const TokenIcon = styled.div<{ imageUrl: string }>`
  width: 20px;
  height: 20px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const TimeInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`

enum State {
  SettingAmount,
  SettingPaymentMethod,
  SettingInputFields,
  MakingPayment
}

interface IProps {
  checkoutData: CheckoutData
  onCheckout: (
    params: {
      selectedMethod: IManagedPaymentMethod
      tokenAmount: string
    },
  ) => void
  isSuccess: boolean
  managedPaymentDetails?: IManagedPaymentMethodDetails
  successElement: ReactElement
  isPreProcessingPayment: boolean
  errorMessage?: string
  onDiscountCodeEntered?: (discountCode: string) => void
  onCurrency?: (currency: string) => void
  resetDiscountCode?: () => void
  onInputFieldsChange?: (inputs: InputFieldWithValue[]) => void
  paymentStatus?: IManagedPaymentStatus
  order?: IPaymentLinkOrder
}

function Presale(props: IProps): ReactElement {
  const [customizations, setCustomizations] = useState<any>(({
    leftPanelColor: 'white',
    primaryButtonColor: 'black'
  }))

  const [fiatAmount, setFiatAmount] = useState<string>()
  const [tokenAmount, setTokenAmount] = useState<string>()
  const [bonusTokenAmount, setBonusTokenAmount] = useState<string>()

  const [selectedMethod, setSelectedPaymentMethod] = useState(props.checkoutData.gateway.managed?.methods[0])
  const [state, setState] = useState(State.SettingAmount)
  const [startsAt, setStartsAt] = useState<Date>()
  const [endsAt, setEndsAt] = useState<Date>()
  const [nextPriceIncreaseAt, setNextPriceIncreaseAt] = useState<Duration>()

  useEffect(() => {
    setSelectedPaymentMethod(props.checkoutData.gateway.managed?.methods[0])
  }, [props.checkoutData.gateway.managed?.methods])

  if (!props.checkoutData.products || props.checkoutData.products?.length <= 0) {
    return <></>
  }

  const presaleData = props.checkoutData.presaleData
  const token = props.checkoutData.products[0]
  const titleTextColor = pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 1)', 'rgba(0, 0, 0, 1)')
  const lightTextColor = pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.05)', 'rgba(0, 0, 0, 0.05)')
  const mediumTextColor = pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.1)')
  const textColor = pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.45)', 'rgba(0, 0, 0, 0.45)')
  const darkTextColor = pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.65)', 'rgba(0, 0, 0, 0.65)')
  const buttonTextColor = pickTextColorBasedOnBgColor(customizations.primaryButtonColor, 'rgba(255, 255, 255, 0.95)', 'rgba(0, 0, 0, 0.95)')
  const [priceMultiplier, setPriceMultiplier] = useState(1)

  const getBonus = (amount: number, bonusLevels: bonusLevel[]): number => {
    for (const level of bonusLevels) {
      if (amount >= level.greaterThan && amount < level.lessThan) {
        return level.bonus
      }
    }
    return 0
  }

  const setTokenAmountFromFiatAmount = (fiatAmount: number): void => {
    const bonus = getBonus(fiatAmount / token.product.price, presaleData?.tokenPresaleData?.bonus ?? [])
    const bonusMultiplier = 1 + (bonus ?? 0) / 100

    const price = (token.product.price * priceMultiplier) / bonusMultiplier
    const tokenAmount = Number(fiatAmount) / price

    setBonusTokenAmount((tokenAmount - Number(fiatAmount) / token.product.price).toString())
    setTokenAmount(round(tokenAmount, token.product.productType?.Presale?.Token?.decimals).toString())
  }

  const setFiatAmountFromTokenAmount = (tokenAmount: number): void => {
    const bonus = getBonus(tokenAmount, presaleData?.tokenPresaleData?.bonus ?? [])
    const bonusMultiplier = 1 + (bonus ?? 0) / 100
    const price = (token.product.price * priceMultiplier) / bonusMultiplier
    const fiatAmount = Number(tokenAmount) * price
    setBonusTokenAmount((Number(tokenAmount) - fiatAmount / token.product.price).toString())
    setFiatAmount(round(fiatAmount, 2).toString())
  }

  const updateIntervals = (): void => {
    const { startsAt, endsAt, pricingStrategies } = presaleData?.tokenPresaleData ?? {}
    setStartsAt(startsAt ? new Date(startsAt) : undefined)
    setEndsAt(endsAt ? new Date(endsAt) : undefined)
    setNextPriceIncreaseAt(undefined)
    setPriceMultiplier(1)

    if (pricingStrategies) {
      const { timeBased } = pricingStrategies
      if (timeBased) {
        const { startsAt, roundDurationSeconds, priceIncreasePercentage } = timeBased

        const roundDuration = roundDurationSeconds * 1000 || 1000
        const start = new Date(startsAt).getTime()
        const now = new Date().getTime()
        let nextIncreaseAt: number
        if (start > now) {
          nextIncreaseAt = start + roundDuration
        } else {
          nextIncreaseAt = start + Math.ceil((now - start) / roundDuration) * roundDuration

          const multipliers = Math.floor((now - start) / roundDuration)
          const percentIncrease = 1 + multipliers * priceIncreasePercentage
          setPriceMultiplier(percentIncrease)
        }

        setNextPriceIncreaseAt(intervalToDuration({ start: new Date(), end: new Date(nextIncreaseAt) }))
      }
    }
  }

  useEffect(() => {
    setCustomizations(props.checkoutData.customizations ?? customizations)

    const interval = setInterval(() => updateIntervals(), 1000)
    updateIntervals()

    return () => clearInterval(interval)
  }, [props.checkoutData])

  useEffect(() => {
    setErrorMessage(props.errorMessage)
  }, [props.errorMessage])

  const [managedPaymentQrCode, setManagedPaymentQrCode] = useState('')
  const generateManagedPaymentQrCode = async (): Promise<void> => {
    if (!props.managedPaymentDetails) {
      return
    }

    const darkColor = pickTextColorBasedOnBgColor(customizations.leftPanelColor, '#00000000', '#ffffff00')
    const lightColor = pickTextColorBasedOnBgColor(customizations.primaryButtonColor, '#000000', '#000000')
    const [qrCodeData] = await generateQRCode(props.managedPaymentDetails, lightColor, darkColor, true)
    setManagedPaymentQrCode(qrCodeData)
  }

  const [errorMessage, setErrorMessage] = useState<string>()

  const { disconnect } = useDisconnect()
  const { address, isConnected, connector } = useAccount()
  const { open } = useWeb3Modal()
  const [isWalletLoading, setIsWalletLoading] = useState(false)
  const onClickOpenWallet = async (): Promise<void> => {
    if (!props.managedPaymentDetails) {
      return
    }

    switch (props.managedPaymentDetails.network) {
    case 'Bitcoin':
    case 'Solana':
    case 'Zcash':
    case 'ZcashTestnet':
      break
    case 'Ethereum':
    case 'SepoliaTestnet':
    case 'Polygon':
    case 'PolygonTestnet':
    case 'BNB':
    case 'BNBTestnet':
    case 'Base':
    case 'Arbitrum':
    case 'Avalanche':
      try {
        setErrorMessage('')
        if (!address) {
          await open()
          return
        }

        if (!selectedMethod) {
          return
        }

        setIsWalletLoading(true)

        const chain = NetworkToChainMap[props.managedPaymentDetails.network] as any
        const provider: any = await connector?.getProvider()

        const signer = createWalletClient({
          chain,
          transport: custom(provider)
        })

        if (connector?.switchChain) {
          const chainId = await connector.getChainId()
          if (chainId !== chain.id) {
            await connector?.switchChain({ chainId: chain.id })
          }
        }

        if (props.managedPaymentDetails.token) {
          const publicClient = createPublicClient({
            chain,
            transport: http()
          })

          const contract = getContract({
            address: props.managedPaymentDetails.token as any,
            abi: erc20Abi,
            client: { public: publicClient, wallet: signer }
          })

          const balance = await contract.read.balanceOf([address])
          const decimals = await contract.read.decimals()
          const parsedAmount = parseUnits(props.managedPaymentDetails.amount.toString(), decimals)
          if (balance < parsedAmount) {
            setIsWalletLoading(false)
            return setErrorMessage(`Your wallet does not have enough ${getMethod(selectedMethod.network, selectedMethod.token).name} for payment.`)
          }

          await contract.write.transfer([
            props.managedPaymentDetails.paymentAddress as Address,
            parseUnits(props.managedPaymentDetails.amount, decimals)
          ], { account: address, chain })
        } else {
          const publicClient = createPublicClient({
            chain,
            transport: http()
          })

          try {
            const balance = await publicClient.getBalance({ address })
            if (balance < parseEther(props.managedPaymentDetails.amount)) {
              setIsWalletLoading(false)
              return setErrorMessage('Your wallet does not have enough ether for payment.')
            }
          } catch (err) {

          }

          if (!signer) return

          await signer.sendTransaction({
            account: address,
            chain,
            to: props.managedPaymentDetails.paymentAddress as Address,
            value: parseEther(props.managedPaymentDetails.amount)
          })

          setIsWalletLoading(false)
        }
      } catch (err) {
        setIsWalletLoading(false)
        // setErrorMessage('Failed to open wallet. Please ensure you have enough funds.')
        console.error(err)
      }
      break
    default:
      console.error('No wallet')
    }
  }

  useEffect(() => {
    if (props.managedPaymentDetails) {
      generateManagedPaymentQrCode()
    }
  }, [props.managedPaymentDetails])

  return <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    minHeight: '100%'

  }}>
    <div style={{
      width: 450,
      minHeight: 550,
      maxWidth: '100%',
      background: customizations.leftPanelColor,
      padding: 30,
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden'
    }}>

      {
        (props.paymentStatus ? Number(props.paymentStatus.amountRemaining) > 0 : true) &&
          props.managedPaymentDetails &&
        <WaveContainer style={{ zIndex: 0, pointerEvents: 'none', opacity: 0.15 }}>
          <Wave style={{ top: 150 }} />
        </WaveContainer>
      }

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 30,
        textAlign: 'center',
        zIndex: 0
      }}>
        <span style={{
          fontSize: Number(presaleData?.tokenPresaleData?.customizations?.titleFontSize ?? 46),
          fontWeight: 800,
          color: titleTextColor
        }}>
          {
            presaleData?.tokenPresaleData?.customizations?.title ??
            `BUY $${token.product.productType?.Presale?.Token?.ticker}`
          }
        </span>
        <span style={{ color: textColor }}>
          1 {token.product.productType?.Presale?.Token?.ticker} = {
            formatCurrency(token.product.currency, token.product.price * priceMultiplier, 10)
          }
        </span>
      </div>

      {
        state === State.SettingAmount &&
        !nextPriceIncreaseAt &&
         endsAt && endsAt.getTime() > new Date().getTime() &&
         (!startsAt || (startsAt && startsAt.getTime() < new Date().getTime())) &&
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 30 }}>
          <div style={{ padding: '10px 20px', color: darkTextColor, backgroundColor: lightTextColor, borderRadius: 10 }}>
            <span>Presale ends in {formatDistanceToNow(endsAt)}</span>
          </div>
        </div>
      }

      {
        state === State.SettingAmount &&
        nextPriceIncreaseAt &&
        (!endsAt || (endsAt && endsAt.getTime() > new Date().getTime())) &&
        (!startsAt || (startsAt && startsAt.getTime() < new Date().getTime())) &&
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 30,
          width: '100%'
        }}>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            borderRadius: 10,
            gap: 10
          }}>
            <span style={{ fontSize: 14, color: titleTextColor }}>Next price increase in</span>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
              border: `1px solid ${mediumTextColor}`,
              borderRadius: 10,
              overflow: 'hidden'
            }}>
              <div style={{
                alignItems: 'center',
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                width: '100%',
                padding: 15
              }}>
                {
                  ['days', 'hours', 'minutes', 'seconds'].map(t => <TimeInnerContainer key={t}>
                    <span style={{
                      textTransform: 'uppercase',
                      color: darkTextColor,
                      fontSize: 10
                    }}>{startCase(t)}</span>
                    <span style={{
                      color: titleTextColor,
                      fontSize: 18
                    }}>{nextPriceIncreaseAt[t]}</span>
                  </TimeInnerContainer>

                  )
                }
              </div>

              {
                endsAt && endsAt.getTime() > new Date().getTime() &&
                <div style={{
                  color: darkTextColor,
                  fontSize: 12,
                  textAlign: 'center',
                  backgroundColor: lightTextColor,
                  width: '100%',
                  padding: 3
                }}>
                  Presale ends in {formatDistanceToNow(endsAt)}
                </div>
              }

            </div>

          </div>
        </div>
      }

      {
        (props.paymentStatus ? Number(props.paymentStatus.amountRemaining) > 0 : true) &&
        props.managedPaymentDetails &&
        <Wrapper style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          gap: 30
        }}>
          {
            props.paymentStatus?.status !== 'expired' &&
              <QRCodeWrapper style={{ boxShadow: 'initial' }}>
                <QRCodeWrapperInner style={{
                  border: '1px solid',
                  borderColor: textColor,
                  background: 'white',
                  borderRadius: 10,
                  width: 168,
                  height: 168,
                  position: 'relative',
                  overflow: 'hidden',
                  padding: 3
                }}>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    opacity: 0.15,
                    zIndex: 0,
                    left: 0,
                    top: 0
                  }}/>
                  <img src={managedPaymentQrCode} style={{
                    width: 160,
                    height: 160,
                    position: 'relative',
                    zIndex: 1
                  }} />
                </QRCodeWrapperInner>
              </QRCodeWrapper>
          }

          <div style={{
            display: 'flex',
            rowGap: 20,
            columnGap: 30,
            flexWrap: 'wrap',
            alignItems: 'center'
          }}>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 12,
              gap: 3
            }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <span style={{ opacity: 0.5, textTransform: 'uppercase', color: darkTextColor }}>Amount due</span>
                {
                  props.paymentStatus?.status !== 'expired' &&
                  <CopyButton
                    style={{
                      background: lightTextColor,
                      color: textColor
                    }}
                    iconColor={textColor}
                    onClick={async () =>
                      await navigator.clipboard.writeText(formatNumber(
                        props.managedPaymentDetails?.amount as any, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 18
                        }))
                    } />
                }
              </div>

              <div style={{
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                fontWeight: 300,
                color: titleTextColor
              }}>
                <img
                  src={getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).logo}
                  style={{ height: 20, width: 'auto' }}
                />
                {
                  formatNumber(props.managedPaymentDetails.amount as any, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 18
                  })
                }
                <span style={{ opacity: 0.5, fontWeight: 100 }}>
                  {getMethod(props.managedPaymentDetails.network, props.managedPaymentDetails.token).ticker}
                </span>
                <span>
                  on {selectedMethod?.network}
                </span>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12, gap: 3, alignItems: 'start' }}>
              <span style={{ opacity: 0.5, textTransform: 'uppercase', textAlign: 'center', color: darkTextColor }}>Time remaining</span>
              <span style={{ fontSize: 16, fontWeight: 100, textAlign: 'center', color: titleTextColor }}>
                {
                  formatSecondsToMinutesAndSecondsString(
                    (new Date(props.managedPaymentDetails.expiresAt).getTime() - new Date().getTime()) / 1000
                  )
                }
              </span>
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 12,
              gap: 3
            }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <span style={{ opacity: 0.5, textTransform: 'uppercase', color: darkTextColor }}>Payment address</span>
                {
                  props.paymentStatus?.status !== 'expired' &&
                    <CopyButton
                      style={{
                        background: lightTextColor,
                        color: textColor
                      }}
                      iconColor={textColor}
                      onClick={async () =>
                        await navigator.clipboard.writeText(props.managedPaymentDetails?.paymentAddress ?? '')
                      } />
                }
              </div>
              <span style={{
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                fontWeight: 100,
                color: titleTextColor,
                overflowWrap: 'anywhere',
                textAlign: 'left'
              }}>
                {props.paymentStatus?.status === 'expired'
                  ? 'Expired'
                  : props.managedPaymentDetails.paymentAddress
                }
              </span>
            </div>

          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: address ? lightTextColor : 'initial',
            borderBottomLeftRadius: 25,
            borderBottomRightRadius: 25,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5
          }}>

            {
              selectedMethod &&
              !getMethod(selectedMethod.network, selectedMethod.token).isSolana && <>

                {
                  address &&
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 10
                  }}>

                    <div style={{
                      color: darkTextColor,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 5
                    }}>

                      <span style={{ textTransform: 'uppercase', fontSize: 10 }}>Connected wallet</span>
                      <span>{shortAddress(address, 10)}</span>
                    </div>

                    <SecondaryButton
                      disabled={isWalletLoading}
                      onClick={() => disconnect()}
                      style={{
                        padding: 0,
                        border: 0,
                        color: titleTextColor,
                        textTransform: 'uppercase',
                        fontSize: 10
                      }}>
                      Disconnect
                    </SecondaryButton>
                  </div>
                }

                <PrimaryButton
                  onClick={onClickOpenWallet}
                  isLoading={isWalletLoading}
                  spinnerColor={buttonTextColor}
                  style={{
                    background: customizations.primaryButtonColor,
                    color: buttonTextColor,
                    width: '100%'
                  }}>
                  {
                    isConnected ? 'Open wallet' : 'Connect wallet'
                  }
                </PrimaryButton>
              </>
            }

            {
              selectedMethod &&
              getMethod(selectedMethod.network, selectedMethod.token).isSolana &&
              <SolanaWallet
                managedPaymentDetails={props.managedPaymentDetails}
                isLoading={isWalletLoading}
                setIsLoading={setIsWalletLoading}
                primaryButtonColor={props.checkoutData.customizations?.primaryButtonColor}
                presaleStyling={{
                  titleTextColor,
                  darkTextColor,
                  buttonTextColor
                }} />
            }

          </div>
        </Wrapper>
      }

      {
        props.paymentStatus?.status === 'expired' &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          flexDirection: 'column',
          gap: 5
        }}>
          <span style={{ fontSize: 18, color: titleTextColor }}>Payment expired</span>
          <span style={{ color: darkTextColor, textAlign: 'center', marginBottom: 20 }}>The payment timer has expired.</span>

          <PrimaryButton
            onClick={() => window.location.reload()}
            isLoading={props.isPreProcessingPayment}
            style={{
              background: customizations.primaryButtonColor,
              color: buttonTextColor,
              width: '100%'
            }}>
              Refresh
          </PrimaryButton>
        </div>
      }

      {
        Number(props.paymentStatus?.amountRemaining) <= 0 &&
        props.paymentStatus?.status !== 'expired' &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1
        }}>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 30,
            width: '100%',
            alignItems: 'center'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              flexDirection: 'column'
            }}>
              <CheckmarkWrapper style={{ background: customizations.primaryButtonColor, width: 45, height: 45 }}>
                <Checkmark animationDelay='0.5s' animationSpeed='0.75s' stroke={buttonTextColor} />
              </CheckmarkWrapper>
              <span style={{ fontSize: 18, color: titleTextColor }}>Payment successful</span>
            </div>
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, auto)',
              rowGap: 10,
              columnGap: 20,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid',
              borderColor: lightTextColor,
              borderRadius: 25,
              padding: 20
            }}>
              <span style={{ color: textColor }}>Order ID</span>
              <div style={{ display: 'flex', gap: 5, alignItems: 'center', color: titleTextColor }}>
                <span>{props.order?.id}</span>
              </div>

              <div style={{ gridColumn: 'span 2', borderBottom: '1px solid', margin: '5px 0', borderColor: textColor, opacity: 0.25 }} />

              <span style={{ color: textColor }}>Tokens purchased</span>
              <div style={{ display: 'flex', gap: 5, alignItems: 'center', color: titleTextColor }}>
                {
                  token.product.imageUrl &&
                  <TokenIcon imageUrl={token.product.imageUrl} />
                }
                <span>{tokenAmount}</span>
              </div>

              <div style={{ gridColumn: 'span 2', borderBottom: '1px solid', margin: '5px 0', borderColor: textColor, opacity: 0.25 }} />

              {
                props.checkoutData.inputFields?.map((iF, i) => <>
                  <span style={{ color: textColor }}>{iF.inputLabel}</span>
                  <span style={{
                    color: titleTextColor,
                    textOverflow: 'ellipsis',
                    overflow: 'auto'
                  }}>{iF.value}</span>
                  {
                    props.checkoutData.inputFields &&
                    i < props.checkoutData.inputFields.length - 1 &&
                    <div style={{ gridColumn: 'span 2', borderBottom: '1px solid', borderColor: textColor, opacity: 0.25 }} />
                  }
                </>)
              }

            </div>
          </div>

        </div>
      }

      {
        !props.managedPaymentDetails &&
        <>
          {
            state === State.SettingPaymentMethod &&
            <Wrapper style={{
              display: 'flex',
              flexGrow: 1,
              overflow: 'scroll',
              border: `1px solid ${lightTextColor}`,
              borderRadius: 15,
              maxHeight: 340
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                width: '100%',
                height: 'fit-content',
                padding: 10
              }}>
                {props.checkoutData.gateway.managed?.methods.map((method, i) => {
                  const m = getMethod(method.network, method.token)
                  return <PrimaryButton
                    onClick={() => {
                      setSelectedPaymentMethod(method)
                      setState(State.SettingAmount)
                    }}
                    key={i}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left',
                      gap: 5,
                      background: lightTextColor,
                      padding: 15,
                      borderRadius: 15,
                      fontSize: 14
                    }}>

                    <div style={{
                      width: 20,
                      height: 20,
                      backgroundImage: `url(${m.logo})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      position: 'relative'
                    }}>
                      {
                        m.tokenInfo && <div style={{
                          background: 'rgba(255, 255, 255, 0.95)',
                          borderRadius: '100%',
                          width: 12,
                          height: 12,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          bottom: -1,
                          right: -5,
                          boxShadow: '0 0 1px'
                        }}>
                          <div style={{
                            width: 8,
                            height: 8,
                            backgroundImage: `url(${m.tokenInfo.logo})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }} />
                        </div>
                      }
                    </div>
                    <span style={{ color: titleTextColor }}>
                      {
                        m.tokenInfo
                          ? `${m.name} on ${m.tokenInfo.networkName}`
                          : m.name
                      }
                    </span>
                  </PrimaryButton>
                })}
              </div>
            </Wrapper>
          }

          {
            state === State.SettingAmount &&
            <>

              <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: 15, justifyContent: 'center' }}>

                {
                  presaleData?.tokenPresaleData?.totalRaiseAmount &&
                  <div style={{ display: 'flex', gap: 10, flexDirection: 'column', marginBottom: 10 }}>
                    <div style={{ width: '100%', background: lightTextColor, height: 15, borderRadius: 25, overflow: 'hidden' }}>
                      <div style={{
                        width: `${Number(presaleData?.tokenPresaleData.totalAmountRaised ?? 0) /
                        Number(presaleData?.tokenPresaleData.totalRaiseAmount) * 100}%`,
                        height: '100%',
                        background: customizations.primaryButtonColor
                      }} />
                    </div>

                    <div style={{
                      textTransform: 'uppercase',
                      fontSize: 12,
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: textColor,
                      fontWeight: 600
                    }}>
                      <span>{token.product.currency} raised</span>
                      <span>
                        {
                          formatCurrency(
                            token.product.currency,
                            presaleData?.tokenPresaleData.totalAmountRaised ?? 0
                          )
                        }
                        {
                          !presaleData?.tokenPresaleData?.customizations?.hideTotalRaiseGoal && <>
                            / {
                              formatCurrency(
                                token.product.currency,
                                presaleData?.tokenPresaleData.totalRaiseAmount
                              )
                            }
                          </>
                        }
                      </span>
                    </div>
                  </div>
                }

                <div style={{
                  border: `1px solid ${mediumTextColor}`,
                  borderRadius: 15,
                  padding: 15,
                  color: textColor,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center', marginBottom: 10 }}>

                      <div>You pay ({token.product.currency})</div>
                      {
                        fiatAmount &&
                        Number(fiatAmount) < Number(presaleData?.tokenPresaleData?.minimumPurchaseAmount ?? 0) &&
                        <div style={{ color: 'red', textTransform: 'uppercase', fontSize: 10 }}>
                          Min {presaleData?.tokenPresaleData?.minimumPurchaseAmount}
                        </div>
                      }
                    </div>
                    <TextInput
                      placeholder={formatCurrencyText(token.product.currency, fiatAmount ? Number(fiatAmount) : 0)}
                      disabled={props.isPreProcessingPayment}
                      value={fiatAmount}
                      onChange={e => {
                        if (!isNaN(e.target.value as any)) {
                          const amount = e.target.value as any
                          setFiatAmount(amount)
                          setTokenAmountFromFiatAmount(amount)
                        }
                      }}
                      placeholderColor={textColor}
                      style={{
                        border: 0,
                        boxShadow: 'none',
                        padding: 0,
                        fontSize: 16,
                        background: 'none',
                        color: titleTextColor
                      }} />
                  </div>

                  <PrimaryButton
                    onClick={() => setState(State.SettingPaymentMethod)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 5,
                      background: lightTextColor,
                      padding: '5px 10px',
                      borderRadius: 15,
                      fontSize: 14
                    }}>
                    {
                      selectedMethod && <>
                        <div style={{
                          width: 20,
                          height: 20,
                          backgroundImage: `url(${getMethod(selectedMethod.network, selectedMethod.token).logo})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          position: 'relative'
                        }}>
                          {
                            getMethod(selectedMethod.network, selectedMethod.token).tokenInfo &&
                            <div style={{
                              background: 'rgba(255, 255, 255, 0.95)',
                              borderRadius: '100%',
                              width: 12,
                              height: 12,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'absolute',
                              bottom: -1,
                              right: -5,
                              boxShadow: '0 0 1px'
                            }}>
                              <div style={{
                                width: 8,
                                height: 8,
                                backgroundImage: `url(${getMethod(selectedMethod.network, selectedMethod.token).tokenInfo?.logo})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                              }} />
                            </div>
                          }
                        </div>
                        <span style={{ color: titleTextColor }}>
                          {getMethod(selectedMethod.network, selectedMethod.token).name}
                        </span>
                      </>
                    }
                  </PrimaryButton>
                </div>

                <div style={{
                  border: `1px solid ${mediumTextColor}`,
                  borderRadius: 15,
                  padding: 15,
                  color: textColor,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <div style={{ flexGrow: 1 }}>
                    <div style={{ marginBottom: 10 }}>
                      <span>
                      You receive
                      </span>
                      {
                        bonusTokenAmount &&
                        bonusTokenAmount !== '0' &&
                        presaleData?.tokenPresaleData?.customizations?.bonusTitle &&
                        presaleData?.tokenPresaleData?.customizations?.bonusTitle !== '' &&
                        <span style={{
                          color: titleTextColor,
                          borderRadius: 5,
                          boxShadow: '0px 4px 6px rgba(0, 0.1, 0.1, 0.2)',
                          fontSize: 10,
                          padding: '5px 10px',
                          justifyContent: 'center',
                          marginLeft: 5
                        }}>
                          {presaleData.tokenPresaleData?.customizations?.bonusTitle ?? ''}
                        </span>

                      }
                    </div>
                    <TextInput
                      disabled={props.isPreProcessingPayment}
                      placeholder='0'
                      value={tokenAmount}
                      onChange={e => {
                        if (!isNaN(e.target.value as any)) {
                          const amount = e.target.value as any
                          setTokenAmount(amount)
                          setFiatAmountFromTokenAmount(amount)
                        }
                      }}
                      placeholderColor={textColor}
                      style={{
                        border: 0,
                        boxShadow: 'none',
                        padding: 0,
                        fontSize: 16,
                        background: 'none',
                        color: titleTextColor
                      }} />
                  </div>

                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    background: lightTextColor,
                    padding: '5px 10px',
                    borderRadius: 15
                  }}>
                    {
                      token.product.imageUrl &&
                      <TokenIcon imageUrl={token.product.imageUrl} />
                    }
                    <span style={{ color: titleTextColor }}>
                      {token.product.productType?.Presale?.Token?.ticker}
                    </span>
                  </div>

                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                  {
                    [...new Set(props.checkoutData.gateway.managed?.methods
                      .map(p => getMethod(p.network, p.token).logo))]
                      .map((p, i) =>
                        <img key={i} style={{ height: 20, maxWidth: 20 }} src={p} />
                      )}
                </div>

                <PrimaryButton
                  disabled={
                    Number(fiatAmount ?? 1) < Number(presaleData?.tokenPresaleData?.minimumPurchaseAmount ?? 0)
                  }
                  onClick={() => {
                    if (startsAt && startsAt.getTime() > new Date().getTime()) {
                      return
                    }

                    if (endsAt && endsAt.getTime() < new Date().getTime()) {
                      return
                    }

                    if (selectedMethod && tokenAmount) {
                      setState(State.SettingInputFields)
                    }
                  }}
                  isLoading={props.isPreProcessingPayment}
                  style={{
                    background: customizations.primaryButtonColor,
                    color: buttonTextColor
                  }}>
                  {
                    startsAt && startsAt.getTime() > new Date().getTime()
                      ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Presale starts in</span>
                        <span style={{ fontSize: 12, opacity: 0.5 }}>
                          {formatDuration(intervalToDuration({ start: new Date(), end: startsAt }))}
                        </span>
                      </div>
                      : endsAt && endsAt.getTime() < new Date().getTime()
                        ? 'Presale has ended'
                        : 'Continue'
                  }
                </PrimaryButton>
              </Wrapper>

            </>
          }
        </>
      }

      {
        !props.managedPaymentDetails &&
        state === State.SettingInputFields &&
        <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>

          <div style={{ display: 'flex', columnGap: 40, rowGap: 20, flexWrap: 'wrap' }}>

            <div style={{
              color: textColor,
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div style={{ marginBottom: 10 }}>Token amount</div>
              <div style={{ display: 'flex', gap: 5, alignItems: 'center', color: titleTextColor }}>
                {
                  token.product.imageUrl &&
                  <TokenIcon imageUrl={token.product.imageUrl} />
                }
                <span>{round(Number(tokenAmount) - Number(bonusTokenAmount), 6)}</span>
              </div>
            </div>

            <div style={{
              color: textColor,
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div style={{ marginBottom: 10 }}>Payment method</div>
              <div style={{ display: 'flex', gap: 5, alignItems: 'center', color: titleTextColor }}>
                {
                  selectedMethod && <>
                    <img
                      style={{ height: 20, maxWidth: 20 }}
                      src={getMethod(selectedMethod.network, selectedMethod.token).logo} />

                    <span style={{ opacity: 0.75, fontWeight: 100 }}>
                      {getMethod(selectedMethod.network, selectedMethod.token).ticker}
                    </span>
                    <span>
                       on {selectedMethod?.network}
                    </span>
                  </>
                }
              </div>
            </div>

            {
              presaleData?.tokenPresaleData?.bonus !== undefined &&
                presaleData?.tokenPresaleData?.bonus !== null &&
                bonusTokenAmount &&
                bonusTokenAmount !== '0' &&
                <div style={{
                  color: textColor,
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div style={{ marginBottom: 10 }}>Bonus reward</div>
                  <div style={{ display: 'flex', gap: 5, alignItems: 'center', color: titleTextColor }}>
                    {
                      token.product.imageUrl &&
                      <TokenIcon imageUrl={token.product.imageUrl} />
                    }
                    <span>{round(Number(bonusTokenAmount), 6)}</span>
                  </div>
                </div>
            }

          </div>

          {
            props.checkoutData.inputFields?.map((inputField, i) =>

              <div
                key={i}
                style={{
                  border: `1px solid ${mediumTextColor}`,
                  borderRadius: 15,
                  padding: 15,
                  color: textColor,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <div style={{ flexGrow: 1 }}>

                  <div style={{ marginBottom: 10 }}>{inputField.inputLabel}</div>
                  <TextInput
                    autoFocus={i === 0}
                    disabled={props.isPreProcessingPayment}
                    placeholder={`Enter ${inputField.inputLabel.toLowerCase()}`}
                    value={inputField.value as string}
                    onChange={e => {
                      if (!props.checkoutData.inputFields) {
                        return
                      }

                      const target = (e.target as HTMLInputElement)
                      let val = target.value
                      if (inputField.inputLabel === 'Email address') {
                        val = val.toLowerCase()
                      }
                      const newInputs = props.checkoutData.inputFields.slice()
                      newInputs[i].value = val
                      props.onInputFieldsChange?.(newInputs)
                    }}
                    placeholderColor={textColor}
                    style={{
                      border: 0,
                      boxShadow: 'none',
                      padding: 0,
                      fontSize: 16,
                      background: 'none',
                      color: titleTextColor
                    }} />
                </div>
              </div>
            )
          }

          <div style={{ display: 'flex', flexGrow: 1 }} />

          {
            errorMessage &&
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 5,
              width: '100%',
              backgroundColor: 'red',
              borderRadius: 5,
              padding: '10px 15px'
            }}>
              <div style={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                gap: 3,
                textTransform: 'uppercase',
                opacity: 0.75,
                fontSize: 10
              }}>

                <Close style={{ height: 8, width: 8 }} stroke='white' strokeWidth={2} />
                <span>Error</span>
              </div>

              <div style={{ color: 'white' }}>{errorMessage}</div>
            </div>
          }

          <div style={{ display: 'flex', gap: 0 }}>
            <PrimaryButton
              onClick={() => {
                setErrorMessage(undefined)
                setState(State.SettingAmount)
              }}
              disabled={props.isPreProcessingPayment}
              spinnerColor={buttonTextColor}
              style={{
                background: lightTextColor,
                color: textColor,
                borderRadius: 25,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                alignItems: 'center',
                gap: 3
              }}>

              <Chevron fill={textColor} style={{ transform: 'rotate(90deg)', height: 14, width: 14, marginTop: -2 }} />
              <span>Back</span>
            </PrimaryButton>

            <PrimaryButton
              onClick={() => {
                let paidAmount = tokenAmount ?? '0'

                if (selectedMethod && tokenAmount) {
                  if (bonusTokenAmount !== '0') {
                    paidAmount = (Number(paidAmount) - Number(bonusTokenAmount)).toString()
                  }
                  console.log('paidAmount', paidAmount)
                  props.onCheckout({ selectedMethod, tokenAmount: paidAmount })
                }
              }}
              isLoading={props.isPreProcessingPayment}
              spinnerColor={buttonTextColor}
              style={{
                background: customizations.primaryButtonColor,
                color: buttonTextColor,
                flexGrow: 1,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}>
              Continue
            </PrimaryButton>
          </div>
        </Wrapper>
      }

      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 30 }}>
        <a
          href='https://www.radom.com/crypto-presales'
          target='_blank'
          title='Crypto Presale Powered by Radom'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            cursor: 'pointer',
            userSelect: 'none',
            textDecoration: 'none',
            color: textColor
          }} rel="noreferrer"
        >
          <span style={{ fontSize: 8, fontWeight: 100, marginBottom: 2 }}>
      Crypto Presale
          </span>
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 100 }}>
      Powered by
            <RadomLogo
              width={60}
              height={14}
              fill={textColor}
            />
          </span>
          <span style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', padding: 0, overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', border: 0 }}>
      Radom
          </span>
        </a>
      </div>
    </div>
  </div>
}

export default Presale
