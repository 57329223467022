import React, { ReactElement, useEffect, useState } from 'react'
import { RADOM_COLORS, formatSubscriptionDuration } from '../util/Constants'
import RadomLogo from '../icons/Logo2'
import { IconButton, SecondaryButton } from './Button'
import styled from 'styled-components'
import { TextInput } from './Input'
import Arrow from '../icons/Arrow'
import Close from '../icons/Close'
import { shortAddress, pickTextColorBasedOnBgColor } from '../util/Util'
import Radom, { CheckoutItemData, IDiscountCode, IManagedGateway, IManagedPaymentMethodDetails, IManagedPaymentStatus, IManagedTokenDelegatePaymentMethodDetails, InputFieldWithValue, PriceQuote, ProductWithQuantity, Subscription } from '../api/Radom'
import PayWidget from './PayWidget'
import { getTotalSum } from '../util/Products'
import { Token } from '../util/Tokens'
import Spinner from './Spinner'
import { Currencies, formatCurrency, TokenCurrencies } from '../util/Currencies'
import { isEqual } from 'lodash'
import { ManagedPaymentMethod } from '../util/Managed'
import { FadeIn } from './Animations'

export const FadeInWrapper = styled.div`
  animation: ${FadeIn} 0.5s ease;
  font-size: 14px;
  min-width: 100%;
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const LeftSection = styled.div<{ background: string, textColor: string, slantedEdge?: boolean }>`
  background: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 45%;
  position: relative;
  max-height: 100vh;
  ${({ slantedEdge }) => slantedEdge && 'clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);'}
  ${({ slantedEdge }) => slantedEdge && 'padding-right: 70px;'}

  @media (max-width: 900px) {
    padding: 20px;
    clip-path: none;
  }

  @media (max-width: 900px) {
    padding: 50px 20px;
    width: 100%;
  }
`

export const RightSection = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  text-overflow: ellipsis;
  max-height: 100vh;
  width: 55%;
  overflow: auto;
  box-sizing: border-box;

  @media (max-width: 900px) {
    padding: 20px;
  }

  @media (max-width: 900px) {
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    justify-content: initial;
  }
`

export interface CheckoutCustomizations {
  leftPanelColor?: string
  slantedEdge?: boolean
  primaryButtonColor?: string
  allowDiscountCodes?: boolean
}

export interface CheckoutData {
  organizationId?: string
  products?: ProductWithQuantity[]
  lineItems?: CheckoutItemData[]
  subscription?: Subscription
  gateway: {
    managed?: IManagedGateway
  }
  sellerLogoUrl: string
  sellerName: string
  sellerAddress: string
  customizations?: CheckoutCustomizations
  isInvoice?: boolean
  currency?: string
  total?: number
  successUrl?: string
  cancelUrl?: string
  inputFields?: InputFieldWithValue[]
  chargeCustomerNetworkFee: boolean
  presaleData?: PresaleData
}
export interface bonusLevel {
  greaterThan: number
  lessThan: number
  bonus: number
}

export interface PresaleData {
  tokenPresaleData?: {
    totalRaiseAmount?: string
    totalAmountRaised?: string
    minimumPurchaseAmount?: string
    startsAt?: string
    endsAt?: string
    bonus?: bonusLevel[]
    pricingStrategies?: {
      timeBased?: {
        startsAt: string
        roundDurationSeconds: number
        priceIncreasePercentage: number
      }
      goalBased?: any
    }
    customizations?: {
      title?: string
      bonusTitle?: string
      titleFontSize?: number
      hideTotalRaiseGoal?: boolean
    }
  }
}

interface IProps {
  checkoutData: CheckoutData
  onCheckout: (
    params: {
      selfCustodial?: {
        useRadomBalance: boolean
        selectedChainId: number
        selectedToken: Token
      }
      managed?: {
        selectedMethod: ManagedPaymentMethod
      }
      priceQuotes: PriceQuote[]
    },
  ) => void
  isSuccess: boolean
  managedPaymentDetails?: IManagedPaymentMethodDetails
  tokenDelegatePaymentDetails?: IManagedTokenDelegatePaymentMethodDetails
  successElement: ReactElement
  isPreProcessingPayment: boolean
  errorMessage?: string
  onDiscountCodeEntered?: (discountCode: string) => void
  onCurrency?: (currency: string) => void
  resetDiscountCode?: () => void
  discountResult?: {
    success: boolean
    element: ReactElement
  }
  discount?: IDiscountCode
  discountLocked?: boolean
  onInputFieldsChange?: (inputs: InputFieldWithValue[]) => void
  paymentStatus?: IManagedPaymentStatus
}

function Checkout(props: IProps): ReactElement {
  const { checkoutData, onCheckout } = props

  const [localCheckoutData, setLocalCheckoutData] = useState({} as any)
  const [isLoading, setIsLoading] = useState(true)
  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false)
  const [totalSum, setTotalSum] = useState(0)
  const [subscriptionSum, setSubscriptionSum] = useState(0)
  const [currency, setCurrency] = useState('USD')
  const [discountCode, setDiscountCode] = useState('')

  const subscriptionProducts = checkoutData.products?.filter(p => p.product.chargingIntervalSeconds > 0) ?? []
  const firstProduct = subscriptionProducts.length > 0
    ? subscriptionProducts?.[0]
    : checkoutData?.products?.[0] ?? undefined

  const paymentLinkCustomizations = checkoutData.customizations
  const customizations = {
    leftPanelColor: paymentLinkCustomizations?.leftPanelColor ?? RADOM_COLORS.GRAY_MED,
    primaryButtonColor: paymentLinkCustomizations?.primaryButtonColor ?? RADOM_COLORS.ORANGE,
    slantedEdge: paymentLinkCustomizations?.slantedEdge ?? false,
    allowDiscountCodes: paymentLinkCustomizations?.allowDiscountCodes ?? false
  }

  const getPriceQuotes = (): void => {
    setIsLoading(true)
    let currency = 'USD'
    if (subscriptionProducts.length > 0) {
      currency = subscriptionProducts[0]?.product.currency ?? 'USD'
    } else if (checkoutData.products && checkoutData.products.length > 0) {
      currency = checkoutData.products?.[0]?.product?.currency ?? 'USD'
    } else {
      return
    }
    setCurrency(currency)
    const productCurrencies = checkoutData.products?.map(p => p.product.currency) ?? ['USD']
    Radom.getPriceQuotes(productCurrencies, [currency])
      .then(p => {
        // Get price quotes in terms of first product's currency
        const [total, subscriptionTotal] = getTotalSum(checkoutData, p, currency)
        setTotalSum(total)
        setSubscriptionSum(subscriptionTotal)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getPriceQuotes()
  }, [])

  useEffect(() => {
    if (
      isEqual(localCheckoutData.gateway, checkoutData.gateway) &&
      isEqual(localCheckoutData.products, checkoutData.products) &&
      isEqual(localCheckoutData.lineItems, checkoutData.lineItems) &&
      isEqual(localCheckoutData.currency, checkoutData.currency) &&
      isEqual(localCheckoutData.total, checkoutData.total)
    ) {
      return
    }

    setLocalCheckoutData(checkoutData)
    getPriceQuotes()
  }, [checkoutData])

  useEffect(() => {
    if (props.discountResult?.success) {
      setShowDiscountCodeInput(true)
    }
  }, [props.discountResult])

  return <FadeInWrapper>
    <LeftSection
      slantedEdge={customizations.slantedEdge}
      background={customizations.leftPanelColor}
      textColor={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black')}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, maxWidth: '400px', paddingTop: 40, paddingBottom: 40 }}>
        <div style={{ position: 'absolute', top: 30, left: 30 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            {
              checkoutData.sellerLogoUrl &&
              <img src={checkoutData.sellerLogoUrl} style={{ width: 'auto', height: 25, pointerEvents: 'none' }} />
            }
            <span style={{ opacity: 0.75 }}>
              {checkoutData.sellerName || shortAddress(checkoutData.sellerAddress, 5)}
            </span>
          </div>
        </div>
        {
          subscriptionProducts.length > 0 &&
          <>
            <span style={{ fontSize: 28, display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 100, fontSize: 18, opacity: 0.5 }}>Subscribe to</span>
              {subscriptionProducts[0].product.name}
            </span>
            {
              isLoading && <Spinner />
            }
            {
              !isLoading && <div style={{ display: 'flex', alignItems: 'center', fontSize: 54, fontWeight: 100 }}>
                {
                  TokenCurrencies.includes(currency) &&
                  <div style={{ width: 32, marginRight: '8px' }}>{Currencies.find(c => c.ticker === currency)?.icon}</div>
                }
                {formatCurrency(currency, totalSum)}
                {
                  subscriptionProducts.length === checkoutData.products?.length &&
                  <span style={{
                    fontSize: 16,
                    marginLeft: 5,
                    fontWeight: 300,
                    width: 30,
                    opacity: 0.75,
                    display: 'inline-block'
                  }}>
                    per {
                      formatSubscriptionDuration(subscriptionProducts[0].product.chargingIntervalSeconds).toLowerCase()
                    }
                  </span>
                }
              </div>
            }
            {
              subscriptionProducts.length !== checkoutData.products?.length &&
              <span style={{ fontSize: 18, opacity: 0.65 }}>
                Then {formatCurrency(currency, subscriptionSum)} per {
                  formatSubscriptionDuration(subscriptionProducts[0].product.chargingIntervalSeconds).toLowerCase()
                }
              </span>
            }
            {
              checkoutData.products?.length === 1 &&
              firstProduct &&
              firstProduct.product.imageUrl &&
              <img style={{
                marginTop: 10,
                maxWidth: 200,
                maxHeight: 200,
                borderRadius: 5
              }} src={firstProduct.product.imageUrl} />
            }
          </>
        }
        {
          subscriptionProducts.length === 0 &&
          !checkoutData.isInvoice &&
          checkoutData.products &&
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              { checkoutData.products.length > 1 &&
                <span style={{ fontSize: 28 }}>
                  Pay {
                    props.checkoutData.sellerName?.length > 0
                      ? props.checkoutData.sellerName
                      : shortAddress(props.checkoutData.sellerAddress)
                  }
                </span>
              }
              { checkoutData.products.length === 1 &&
                <span style={{ fontSize: 28 }}>{firstProduct?.product.name}</span> }
              { checkoutData.products?.length === 0 && <span style={{ fontSize: 28 }}>Product name</span> }
              {
                checkoutData.products?.length === 1 && firstProduct?.product.description &&
              <span style={{ fontSize: 14, opacity: 0.5 }}>{firstProduct?.product.description}</span>
              }
            </div>
            {
              isLoading && <Spinner />
            }
            {
              !isLoading && <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', fontSize: 54, fontWeight: 100 }}>
                {
                  TokenCurrencies.includes(currency) &&
                  <div style={{ width: 32, marginRight: '8px' }}>{Currencies.find(c => c.ticker === currency)?.icon}</div>
                }
                {formatCurrency(currency, totalSum)}
              </div>
            }
            {
              checkoutData.products?.length === 1 && firstProduct?.product.imageUrl &&
              <img style={{
                marginTop: 10,
                maxWidth: 200,
                maxHeight: 200
              }} src={firstProduct?.product.imageUrl} />
            }
          </>
        }

        {
          customizations.allowDiscountCodes && !showDiscountCodeInput && !props.discountLocked &&
          <IconButton
            onClick={() => { if (props.onDiscountCodeEntered) setShowDiscountCodeInput(true) }}
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: 0,
              color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)')
            }}>
            <span>Add discount code</span>
            <Arrow stroke={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)')} height={15} />
          </IconButton>
        }
        {
          showDiscountCodeInput &&
          <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
            {
              !props.discountResult?.success && !props.discountLocked &&
              <div style={{
                borderRadius: 5,
                border: '1px solid',
                backgroundColor: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.1)', 'rgba(255, 255, 255, 0.25)'),
                color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black'),
                borderColor: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.1)', 'rgba(0, 0, 0, 0.1)'),
                display: 'flex',
                alignItems: 'center'
              }}>

                <TextInput
                  autoFocus
                  placeholder='Enter code'
                  autoCorrect="off"
                  spellCheck="false"
                  type="text"
                  onChange={(e) => setDiscountCode(e.target.value)}
                  placeholderColor={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.25)', 'rgba(0, 0, 0, 0.25)')}
                  style={{
                    color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black'),
                    background: 'none',
                    border: 'none',
                    boxShadow: 'none'
                  }}
                />
                <SecondaryButton
                  onClick={() => props.onDiscountCodeEntered?.(discountCode)}
                  style={{
                    padding: '3px 10px',
                    textTransform: 'uppercase',
                    color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black'),
                    border: 'none'
                  }}>Apply</SecondaryButton>
              </div>
            }
            <div style={{ display: 'flex', gap: 10 }}>
              {props.discountResult?.element}
              {props.discountResult?.success && !props.discountLocked &&
               <IconButton style={{ padding: 5 }} onClick={() => props.resetDiscountCode?.()}>
                 <Close
                   width={15}
                   height={15}
                   stroke={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black')}
                   style={{ opacity: 0.5 }} />
               </IconButton>
              }
            </div>
          </div>
        }
      </div>

      {
        <a
          href='https://radom.com'
          target='_blank'
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 6,
            cursor: 'pointer',
            userSelect: 'none',
            fontSize: 14,
            color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.45)', 'rgba(0, 0, 0, 0.45)'),
            fontWeight: 100,
            position: 'absolute',
            bottom: 30,
            left: 30,
            textDecoration: 'none'
          }} rel="noreferrer">
          <span>Checkout by</span>
          <RadomLogo
            width={50}
            fill={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)')}
          />
        </a>
      }
    </LeftSection>

    <RightSection>
      <div style={{ width: '100%', maxWidth: 420 }}>
        <PayWidget
          checkoutData={checkoutData}
          currency={firstProduct?.product.currency ?? 'USD'}
          isPreProcessingPayment={props.isPreProcessingPayment}
          paymentStatus={props.paymentStatus}
          isSuccess={props.isSuccess}
          managedPaymentDetails={props.managedPaymentDetails}
          tokenDelegatePaymentDetails={props.tokenDelegatePaymentDetails}
          discount={props.discount}
          onCheckout={onCheckout}
          onInputFieldsChange={props.onInputFieldsChange}
          onQuoteUpdate={() => getPriceQuotes()}
          successElement={props.successElement}
          errorMessage={props.errorMessage}
        />
      </div>
    </RightSection>
  </FadeInWrapper>
}

export default Checkout
