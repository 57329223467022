import React from 'react'
import { RADOM_COLORS } from './Constants'
import { Tokens } from './Tokens'

export const Currencies = [
  {
    icon: <div style={{ width: 'inherit', opacity: 0.4 }}>$</div>,
    ticker: 'USD'
  },
  {
    icon: <div style={{ width: 'inherit', opacity: 0.4 }}>$</div>,
    ticker: 'CAD'
  },
  {
    icon: <div style={{ width: 'inherit', opacity: 0.4 }}>£</div>,
    ticker: 'GBP'
  },
  {
    icon: <div style={{ width: 'inherit', opacity: 0.4 }}>€</div>,
    ticker: 'EUR'
  },
  {
    icon: <img src={Tokens.USDC.logo} style={{ width: 'inherit' }} />,
    ticker: 'USDC'
  },
  {
    icon: <img src={Tokens.USDT.logo} style={{ width: 'inherit' }} />,
    ticker: 'USDT'
  }
]

export const TokenCurrencies = ['USDT', 'USDC', 'ETH']

export const formatCurrency = (currency: string, amount: number | string, decimals: number = 2): React.ReactElement => {
  const effectiveDecimals = decimals !== undefined ? decimals : 2
  const numericAmount = typeof amount === 'number' ? amount : Number(amount)

  if (!TokenCurrencies.includes(currency)) {
    try {
      const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: effectiveDecimals,
        maximumFractionDigits: effectiveDecimals
      }
      const formattedAmount = Intl.NumberFormat('en-US', options).format(numericAmount).replace(/(\.\d*[1-9])0+$|\.0*$/, '$1')

      return <span>{formattedAmount}</span>
    } catch (err) {
      return <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: effectiveDecimals, maximumFractionDigits: effectiveDecimals }).format(amount)}</span>
    }
  }

  const tokenFormattedAmount = numericAmount.toFixed(6).replace(/(\.\d*[1-9])0+$|\.0*$/, '$1')

  return (
    <div style={{ display: 'inline-flex', gap: '4px' }}>
      <span>{tokenFormattedAmount}</span>
      <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>{currency}</span>
    </div>
  )
}

export const formatCurrencyText = (currency: string, amount: number): string => {
  if (!TokenCurrencies.includes(currency)) {
    try {
      return Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount)
    } catch (err) {
      return Intl.NumberFormat('en-US').format(amount)
    }
  }
  return `${Intl.NumberFormat('en-US').format(amount)} ${currency}`
}
