import { camelCase } from 'lodash'
import React, { ReactElement, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Radom, { IDiscountCode, IManagedPaymentMethodDetails, IManagedPaymentStatus, IManagedTokenDelegatePaymentMethodDetails, InputFieldWithValue, IPaymentLink, PaymentMethod } from '../api/Radom'
import Checkout from '../components/Checkout'
import { CheckoutParams } from '../components/PayWidget'
import { CheckmarkWrapper } from '../components/Receipt'
import Spinner from '../components/Spinner'
import Checkmark from '../icons/Checkmark'
import { RADOM_COLORS } from '../util/Constants'
import { formatCurrency } from '../util/Currencies'
import LoadingCheckout from './LoadingCheckout'

function PaymentLink(): ReactElement {
  const paymentLinkId = window.location.pathname.replaceAll('/', '').replaceAll('pay', '')
  const [searchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(true)
  const [paymentLinkData, setPaymentLinkData] = useState<IPaymentLink>()
  const [currency, setCurrency] = useState('USD')

  const [isPreProcessingPayment, setIsPreProcessingPayment] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [countdown, setCountdown] = useState(10)
  const [errorMessage, setErrorMessage] = useState('')
  const [inputFields, setInputFields] = useState<InputFieldWithValue[]>([])
  const [successElement, setSuccessElement] = useState<ReactElement>(<></>)

  const [discountResult, setDiscountResult] = useState({ success: false, element: <></> })
  const [discount, setDiscount] = useState<IDiscountCode>()
  const [discountLocked, setDiscountLocked] = useState(false)

  const [paymentStatus, setPaymentStatus] = useState<IManagedPaymentStatus>()

  const [managedPaymentDetails, setManagedPaymentDetails] = useState<IManagedPaymentMethodDetails>()
  const [
    tokenDelegatePaymentDetails,
    setTokenDelegatePaymentDetails
  ] = useState<IManagedTokenDelegatePaymentMethodDetails>()

  useEffect(() => {
    const discountCode = searchParams.get('discountCode')
    if (discountCode) {
      redeemDiscountCode(discountCode)
    }

    if (paymentLinkData?.inputFields) {
      const inputFields: InputFieldWithValue[] = []
      for (const input of paymentLinkData.inputFields) {
        const prefilledValue = searchParams.get(camelCase(input.inputLabel))
        inputFields.push({ ...input, value: prefilledValue ?? '' })
      }
      setInputFields(inputFields)
    }
  }, [searchParams, paymentLinkData])

  const redeemDiscountCode = async (discountCode: string): Promise<void> => {
    setDiscountResult({ success: false, element: <Spinner /> })

    Radom.redeemDiscountCode({ paymentLinkId, code: discountCode }).then((discount) => {
      setDiscount(discount)
      setDiscountResult({
        success: true,
        element: <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <span>Discount applied!</span>
          <span style={{
            padding: 5,
            backgroundColor: RADOM_COLORS.SUCCESS,
            color: RADOM_COLORS.SUCCESS_LIGHT,
            borderRadius: 5,
            fontWeight: 600,
            fontSize: 12
          }}>
            {
              discount.model.modelType === 'percentage'
                ? new Intl.NumberFormat('en-US', {
                  style: 'percent',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(discount.model.amountOff / 100)
                : formatCurrency(currency, discount.model.amountOff)
            } OFF
          </span>
        </div>
      })
    }).catch(_ => {
      setDiscountResult({
        success: false,
        element: <span style={{ opacity: 0.5, fontSize: 12 }}>Invalid discount code</span>
      })
    })
  }

  const onCheckout = async ({
    managed,
    completeTokenDelegate,
    subscriptionOptions
  }: CheckoutParams): Promise<void> => {
    setErrorMessage('')
    if (!paymentLinkData) {
      setErrorMessage('Payment link data not set. Please refresh and try again.')
      return
    }

    if (managed) {
      setIsPreProcessingPayment(true)

      const { selectedMethod: method } = managed

      let paymentMethod: PaymentMethod = {
        managed: {
          method: {
            network: method.tokenInfo?.networkName ?? method.name,
            token: method.tokenInfo?.tokenAddress ?? undefined
          }
        }
      }

      if (subscriptionOptions) {
        if (subscriptionOptions.autopay) {
          if (!method.tokenInfo) {
            setErrorMessage('Cannot set up an autopay subscription without an EVM based token')
            return
          }

          paymentMethod = {
            managedTokenDelegate: {
              buyerAddress: subscriptionOptions.autopay.address,
              network: method.tokenInfo.networkName,
              token: method.tokenInfo.tokenAddress
            }
          }
        }

        if (subscriptionOptions.emailInvoice) {
          if (!subscriptionOptions.emailInvoice.emailAddress) {
            setErrorMessage('Cannot set up an email invoice subscription without an email address')
            return
          }
        }
      }

      try {
        const res = await Radom.createPaymentLinkOrder({
          paymentLinkId: paymentLinkData.id,
          paymentMethod,
          orderData: inputFields.map(c => {
            return {
              key: c.inputLabel,
              value: c.value.toString()
            }
          }),
          orderProductData: [],
          discountCode: discount?.code,
          emailAddress: subscriptionOptions?.emailInvoice?.emailAddress.toLowerCase()
        })

        setDiscountLocked(true)

        if (res.paymentMethodDetails.managed) {
          setManagedPaymentDetails(res.paymentMethodDetails.managed)
        } else if (res.paymentMethodDetails.managedTokenDelegate) {
          setTokenDelegatePaymentDetails(res.paymentMethodDetails.managedTokenDelegate)
        } else {
          throw new Error('Unexpected empty managed payment method details')
        }

        const checkPaymentInterval = setInterval(() => {
          if (!res.paymentMethodDetails.managed) {
            return
          }

          Radom.getManagedPaymentStatus(res.paymentMethodDetails.managed?.paymentId).then(res => {
            setPaymentStatus(res)

            if (res.status === 'paymentConfirmed') {
              setIsSuccess(true)
              clearInterval(checkPaymentInterval)

              setSuccessElement(
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10
                }}>
                  <CheckmarkWrapper>
                    <Checkmark width={30} height={30} animationDelay='0.5s' animationSpeed='0.75s' />
                  </CheckmarkWrapper>
                  <span style={{ fontSize: 18, color: RADOM_COLORS.BLACK }}>Payment successful</span>
                </div>
              )
            }
          }).catch(() => {})
        }, 1000)
      } catch (err) {
        console.error(err)
        setErrorMessage(err.reason || err.message)
      }

      setIsPreProcessingPayment(false)
      return
    }

    if (completeTokenDelegate) {
      const { paymentId, signature } = completeTokenDelegate

      setIsPreProcessingPayment(true)
      try {
        await Radom.completeTokenDelegatePayment(paymentId, signature)
        const checkPaymentInterval = setInterval(() => {
          Radom.getManagedTokenPaymentStatus(paymentId).then(res => {
            setPaymentStatus(res)

            if (res.status === 'paymentConfirmed') {
              setIsPreProcessingPayment(false)
              setIsSuccess(true)
              clearInterval(checkPaymentInterval)

              setSuccessElement(
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10
                }}>
                  <CheckmarkWrapper>
                    <Checkmark width={30} height={30} animationDelay='0.5s' animationSpeed='0.75s' />
                  </CheckmarkWrapper>
                  <span style={{ fontSize: 18, color: RADOM_COLORS.BLACK }}>Payment successful</span>
                </div>
              )
            }

            if (res.status === 'failed') {
              clearInterval(checkPaymentInterval)
              setIsPreProcessingPayment(false)
              setTokenDelegatePaymentDetails(undefined)
              setErrorMessage('Transaction failed, please try again.')
            }
          }).catch(() => {})
        }, 1000)
      } catch (err) {
        setIsPreProcessingPayment(false)
        setErrorMessage(err.reason || err.message)
      }
      return
    }

    setErrorMessage('Oops, something went wrong.')
  }

  useEffect(() => {
    Radom.getPaymentLink(paymentLinkId).then(data => {
      setPaymentLinkData(data)
      setInputFields(data.inputFields.map(c => {
        return { ...c, value: '' }
      }))
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    let countdownTimer
    let redirectTimer

    if (isSuccess && paymentLinkData?.successUrl) {
      // Start the countdown timer
      const initialCountdown = 10
      countdownTimer = setInterval(() => {
        setCountdown(countdown => countdown - 1)
      }, 1000)

      setCountdown(initialCountdown)
      // Start the redirect timer
      redirectTimer = setTimeout(() => {
        window.location.href = paymentLinkData?.successUrl ?? ''
      }, 3000)
    }

    // Clean up the timers when the component unmounts or the values change
    return () => {
      clearInterval(countdownTimer)
      clearTimeout(redirectTimer)
    }
  }, [isSuccess, paymentLinkData])

  // Update the countdown label whenever the countdown value changes
  useEffect(() => {
    if (countdown < 10 && isSuccess) {
      document.title = `Redirecting in ${countdown} seconds`
    } else if (isSuccess && countdown === 0) {
      document.title = 'Redirecting...'
    }
  }, [countdown])

  if (isLoading || !paymentLinkData || paymentLinkData.products.length <= 0) {
    return <LoadingCheckout />
  }

  return <Checkout
    checkoutData={{
      ...paymentLinkData,
      products: paymentLinkData.products.map(p => {
        return { product: p, quantity: 1 }
      }),
      inputFields
    }}
    onInputFieldsChange={setInputFields}
    onCheckout={onCheckout}
    managedPaymentDetails={managedPaymentDetails}
    tokenDelegatePaymentDetails={tokenDelegatePaymentDetails}
    isPreProcessingPayment={isPreProcessingPayment}
    isSuccess={isSuccess}
    errorMessage={errorMessage}
    onCurrency={currency => setCurrency(currency)}
    onDiscountCodeEntered={async discountCode => await redeemDiscountCode(discountCode)}
    discountResult={discountResult}
    discount={discount}
    discountLocked={discountLocked}
    resetDiscountCode={() => {
      setDiscountResult({ success: false, element: <></> })
      setDiscount(undefined)
    }}
    successElement={successElement}
    paymentStatus={paymentStatus}
  />
}

export default PaymentLink
